<template>
    <div>
        <DxPopup
            :visible="dialog"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="false"
            width="100vw"
            height="100%"
            :shading="true"
            position="bottom"
            :wrapperAttr="{
                class: 'clear-padding',
            }"
            :animation="{
                show: {
                    type: 'slide',
                    duration: 400,
                    from: {
                        position: {
                            my: 'top',
                            at: 'bottom',
                            of: 'window',
                        },
                    },
                },
                hide: {
                    type: 'slide',
                    duration: 400,
                    to: {
                        position: {
                            my: 'top',
                            at: 'bottom',
                            of: 'window',
                        },
                    },
                },
            }"
        >
            <DxButton
                type="normal"
                styling-mode="text"
                icon="mdi mdi-close"
                @click="$emit('closes', false)"
                class="button-style white--text"
                style="position: fixed; top: 10px; left: 8px; z-index: 1"
            />
            <div
                class="full-height row align-center"
                style="width: 100vw; background-color: black"
            >
                <!-- <embed :src="link" alt="" width="100%" height="auto" /> -->
                <!-- <canvas id="canvas"></canvas> -->
                <div class="white--bg py-2" style="width: 100vw">
                    <canvas
                        id="the-canvas"
                        style="
                            direction: ltr;
                            width: 100vw !important;
                            height: auto !important;
                            overflow: auto;
                        "
                    ></canvas>
                </div>
            </div>
        </DxPopup>
    </div>
</template>
<script>
import { DxPopup, DxButton } from "devextreme-vue";
import LenhDienTu from "../../../../class/LenhDienTu";
import Panzoom from "@panzoom/panzoom";

export default {
    layout: "application",
    components: { DxPopup, DxButton },
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        guidLenh: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            link: "",
        };
    },
    methods: {
        async getData() {
            try {
                let lenh = new LenhDienTu();
                lenh.guidLenh = this.guidLenh;
                let link = await lenh.layLinkXemBanTheHienLenh();
                this.pdfViewer(link);
            } catch (error) {
                console.log(
                    "🚀 ~ file: BanTheHienLenh.vue ~ line 69 ~ getData ~ error",
                    error,
                );
            }
        },

        pdfViewer(url) {
            import("../../../utils/pdfjs-2.9.359-dist/build/pdf").then((pdfjsLib) => {
                pdfjsLib.GlobalWorkerOptions.workerSrc = "/mylibs/pdf/pdf.worker.js";

                var loadingTask = pdfjsLib.getDocument(url);
                loadingTask.promise.then(function (pdf) {
                    pdf.getPage(1).then(function (page) {
                        var scale = 1.5;
                        var viewport = page.getViewport({ scale: scale });
                        // Support HiDPI-screens.
                        var outputScale = window.devicePixelRatio || 1;
                        //
                        // Prepare canvas using PDF page dimensions
                        //
                        let arrCanvas = document.querySelectorAll("#the-canvas");

                        arrCanvas.forEach((canvas) => {
                            var context = canvas.getContext("2d");
                            canvas.width = Math.floor(viewport.width * outputScale);
                            canvas.height = Math.floor(viewport.height * outputScale);
                            canvas.style.width = "100vw";
                            canvas.style.height = "auto";
                            var transform =
                                outputScale !== 1
                                    ? [outputScale, 0, 0, outputScale, 0, 0]
                                    : null;
                            //
                            // Render PDF page into canvas context
                            //
                            var renderContext = {
                                canvasContext: context,
                                transform: transform,
                                viewport: viewport,
                            };
                            page.render(renderContext);
                            const panzoom = Panzoom(canvas, {
                                canvas: true,
                            });
                        });
                        // var canvas = document.getElementById("the-canvas");
                    });
                });
            });
        },
    },
    watch: {
        dialog() {
            if (this.dialog == true) {
                this.getData();
            }
        },
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
